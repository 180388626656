@use '../abstracts/colors' as c;
@use '../abstracts/type' as t;
@use '../helpers/variables' as v;

.thrive-toast_info {
    padding: 15px 15px 15px 40px;
    border-radius: v.$border-radius;
    background: map-get(c.$colors, 'info', '100');
    color: map-get(c.$colors, 'info', '200');
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
    position: relative;
    font-family: 'Thrive Light';

    &.embedded {
        box-shadow: none;
    }

    &:before {
        font-family: 'Material Icons';
        content: '\e88e';
        position: absolute;
        top: 15px;
        left: 12px;
        font-size: 20px;
    }

    span {
        font-family: 'Thrive Regular';
    }
}

.thrive-toast_warn {
    padding: 15px 15px 15px 40px;
    border-radius: v.$border-radius;
    background: map-get(c.$colors, 'warning', '100');
    color: map-get(c.$colors, 'warning', '200');
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
    position: relative;
    font-family: 'Thrive Light';

    &.embedded {
        box-shadow: none;
    }

    &:before {
        font-family: 'Material Icons';
        content: '\e002';
        position: absolute;
        top: 15px;
        left: 12px;
        font-size: 20px;
    }

    span {
        font-family: 'Thrive Regular';
    }
}

.thrive-toast_error {
    padding: 15px 15px 15px 40px;
    border-radius: v.$border-radius;
    background: map-get(c.$colors, 'failure', '100');
    color: map-get(c.$colors, 'failure', '200');
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
    position: relative;
    font-family: 'Thrive Light';

    &.embedded {
        box-shadow: none;
    }

    &:before {
        font-family: 'Material Icons';
        content: '\e000';
        position: absolute;
        top: 15px;
        left: 12px;
        font-size: 20px;
    }

    span {
        font-family: 'Thrive Regular';
    }
}
