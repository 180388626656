@use '../abstracts/colors' as c;
@use '../abstracts/type' as t;
@use '../helpers/variables' as v;
@use '../abstracts/mixins' as *;


.container-img{
    position: relative;
    border: 1px dashed map-get(c.$colors ,'secondary','200');
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.108719);
    border-radius: v.$border-radius;
    background: map-get(c.$colors,'base','grey-1');
    height: 332px;
    width: 100%;
    margin-bottom: 16px;
    cursor: pointer;
    .property-container{
      @include get-flex(center,center,$flexDirection:column);
      .property-row{
        @include get-flex(center,space-between,$columnGap:20px);
        .property-column{
          @include get-flex(center,center,$flexDirection:column);
          .property-name{
            font-size: map-get(t.$font ,'size','200');
            color: map-get(c.$colors , 'text','300');
          }
          .property-value{
            color: map-get(c.$colors , 'text','300');
          }
        }
      }
    } 
    .img--1-1,
    .img--9-16,
    .img--16-9,
    .img--4-3{
      height: 100%;
      img{
        height: 100%;
        margin: 0 auto;
        display: block;
      }
      .icon_button{
        position: absolute;
        top: 5px;
        right: 5px;
        @include get-flex(center,space-between,$columnGap:10px);
      }
    }
    .img--1-1 img {
      aspect-ratio: 1/1;
    }
    .img--9-16 img {
      /* aspect-ratio: 9/16; */
      object-fit: cover;
    }
    .img--16-9 img {
      /* aspect-ratio: 16/9; */
      object-fit: cover;
    }
    
    .img--4-3 img {
      aspect-ratio: 4/3;
      object-fit: cover;
      max-width: 100%;
    }
  }