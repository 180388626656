@use '../abstracts/colors' as c;
@use '../abstracts/type' as t;
@use '../helpers/variables' as v;
@use '../abstracts/mixins' as *;

.thrive-card {
    border-radius: v.$border-radius;
    padding: 24px;
    box-shadow: 0px 1px 0.5px rgb(0 0 0 / 0%), 0px 0px 3px 1px rgb(0 0 0 / 15%);
    margin-top: 24px;

    @include respond-to("small") {
        padding: 10px;
        border: none;
        box-shadow: none;
    }

    &.no-hover {
        border: 1px solid map-get(c.$colors, "secondary", "100") !important;
        box-shadow: none;
    }

    &.outline-card {
        border: 1px solid map-get(c.$colors, "secondary", "100");
        box-shadow: none;
    }

    &.no-padding {
        padding: 0px;
    }

    &.hover {
        &:hover {
            box-shadow: 2px 2px 0px 0px #C9C9C9;
        }
    }
}

.metric-card {
    border-radius: v.$border-radius;
    padding: 24px;
    margin-top: v.$space-between-metric-cards;
    border: 0.5px solid #C9C9C9;
    // border: 1px solid transparent;
    box-shadow: 0px 0px 0px 0.5px #C9C9C9;
}
