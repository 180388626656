@use '../abstracts/colors' as c;
@use '../abstracts/type' as f;
@use '../abstracts/mixins' as *;
// we can use this class for form group heading
.form-group_heading{
  font-family: "Thrive Bold";
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: auto;
  line-height: 150%;
  margin-bottom: 10px;
  margin-top: 15px;
  color: map-get(c.$colors, "secondary", "500");
}
// Use this for header who has left side heading and right side something else.
.form-heading_row{
  @include get-flex(center,space-between);
  margin-bottom: 10px;
  margin-top: 15px;
  .form-title{
    font-family: "Thrive Bold";
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: auto;
    line-height: 150%;
    color: map-get(c.$colors, "secondary", "500");
  }
  @include respond-to("small") {
    @include get-flex(flex-start,flex-start,$flexDirection:column);
  }
}

.hint{
  font-size:map-get(f.$font ,'size','100');
}
// use this for radio button who has label
.input-radio_row{
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  @include get-flex(center,space-between,$columnGap:20px);
  @include respond-to("small") {
    @include get-flex(flex-start,$flexDirection:column,$columnGap:20px);
  }
}
// radio button label color change
.radio-label{
  color: map-get(c.$colors, "text", "300");
}
// check box control
.input-checkbox{
  margin-top: 15px;
  margin-bottom: 10px;
  .checkbox-label{
    color: map-get(c.$colors, "text", "300");
  }
}
// label content optional
.sub-label{
  color: map-get(c.$colors,'secondary','200');
}

.input-error{
  color: map-get(c.$colors, 'error','100');
  font-size: 12px;
}