/* You can add global styles to this file, and also import other style files */
@use 'sass/abstracts';
@use 'sass/abstracts/colors' as c;
@use 'sass/base';
@use 'sass/helpers';
@use 'sass/modules';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

body {
    font-family: 'Thrive Light' !important;
    font-weight: 400 !important;
    src: url('https://d2dcafd8b62eqh.cloudfront.net/order-app-fonts/lato-v16-latin-regular.woff2') format('woff2'), url('https://d2dcafd8b62eqh.cloudfront.net/order-app-fonts/lato-v16-latin-regular.woff') format('woff') !important;
    font-display: swap !important;
    overflow: hidden !important;
}

.basic-container {
    background-color: #fff;
    padding: 105px 20px 20px;
    min-height: 100vh;
}

// sweetalert styling
.swal2-actions {
    flex-direction: row-reverse !important;
}

.swal2-styled.swal2-confirm {
    background-color: #FFF32A !important;
    color: #000 !important;
    border: 1px solid #000 !important;
    border-radius: 4px !important;
    box-shadow: 2px 2px 0px 0px #000000;
    padding: 10px 20px !important;
    max-height: 45px;
    height: 45px !important;
    text-transform: uppercase !important;
    font-size: 0.889rem !important;
    font-weight: 700 !important;
    cursor: pointer;
}

.swal2-styled.swal2-default-outline:focus {
    box-shadow: 2px 2px 0px 0px #000000 !important;
    outline: 0;
}

.swal2-styled.swal2-cancel {
    padding: 10px 20px !important;
    max-height: 45px;
    height: 45px !important;
    cursor: pointer;
    background: transparent;
    border-radius: 4px !important;
    border: 1px solid #580D7E !important;
    font-size: 0.889rem !important;
    font-weight: 700 !important;
    background: transparent !important;
    box-shadow: none;
    position: relative;
    color: #580D7E !important;
    --mdc-text-button-label-text-color: #580D7E !important;
    text-transform: uppercase !important;
}

/* tooltip notch for bottom tooltip */
.thrive-tooltip[style*='transform-origin: center top']::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #3a3a3a transparent;
}

/* tooltip notch for left tooltip */
.thrive-tooltip[style*='transform-origin: left center']::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #3a3a3a transparent transparent;
}

/* tooltip notch for right tooltip */
.thrive-tooltip[style*='transform-origin: right center']::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #3a3a3a;
}

/* tooltip notch for top tooltip */
.thrive-tooltip[style*='transform-origin: center bottom']::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #3a3a3a transparent transparent transparent;
}

.thrive-tooltip .mdc-tooltip__surface {
    font-size: 12px;
    line-height: 18px;
    font-family: 'Thrive Regular' !important;
    background: #3a3a3a;
    color: #fff;
    border-radius: 4px;
    padding: 8px;
    text-align: center !important;
}

.color-picker {
    border-radius: 8px;
    height: 380px;
    width: 264px;
}

.saturation-lightness {
    border-radius: 8px;
    height: 152px;
    width: 232px;
}

.focus {
    z-index: 100;
}

// slick carousal css for left right arrow
.slick-prev {
    left: 3% !important;
    z-index: 1;
    width: 20px;
}
.slick-next {
    right: 3% !important;
    z-index: 1;
    width: 20px;
}
.slick-prev:before, .slick-next:before {
    font-size: 35px !important;
    opacity: 1 !important; 
}

.tag-restaurant {
    color: map-get(c.$colors,'link');
}
