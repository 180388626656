@use '../abstracts/mixins' as *;
.thrive-dialog {
    .dialog-header {
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        background-color: #f8f8f8;
    }
    .dialog-content {
        padding: 16px;
        max-height: calc(100vh - 200px);
        overflow-y: overlay;
    }
    .dialog-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 16px;
    }
}
// show full with popup in small device 
.sm-full_width_dailog{
    @include respond-to("small") {
        width: 100vw !important;
        max-width: 100vw !important;
    } 
    // removed footer of dialog if there is no footer in small device
    .sm-without_footer{
        @include respond-to("small") {
            max-height: calc(100vh - 55px) !important;
        }
    } 
}
// open dialog from bottom to top in samll device
.sm-dialog_bottom{
    @include respond-to("small") {
        position: absolute !important;
        bottom: 0px;
    }
}

.thrive-bottom-dialog {
    .dialog-header {
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        background-color: #f8f8f8;
    }
    .dialog-content {
        padding: 16px;
        max-height: calc(100vh - 200px);
        overflow-y: overlay;
    }
    .dialog-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 16px;
    }
}
// show full with popup in small device 
.sm-full_width_dailog{
    @include respond-to("small") {
        width: 100vw ;
        max-width: 100vw !important;
    } 
    // removed footer of dialog if there is no footer in small device
    .sm-without_footer{
        @include respond-to("small") {
            max-height: calc(100vh - 55px) !important;
        }
    } 
}
// open dialog from bottom to top in samll device
.sm-dialog_bottom{
    @include respond-to("small") {
        position: absolute !important;
        bottom: 0px;
    }
}
