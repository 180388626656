@use '../abstracts/colors' as c;
@use '../abstracts/type' as t;
@use '../helpers/variables' as v;

.mat-mdc-form-field-bottom-align::before {
    content: none !important;
}

.mdc-text-field--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--focused .mdc-notched-outline .mdc-notched-outline__trailing {
    --mdc-shape-small: 4px;
    border-color: map-get(c.$colors, "primary", "500") !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 22px !important;

    &.mdc-floating-label--float-above {
        top: 28px !important;
    }
}

.mat-mdc-form-field-error {
    font-size: map-get(t.$font, "size", "200");
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: map-get(c.$colors, "secondary", "100") !important;
}

.mat-form-field-invalid.ng-touched {
    position: relative;

    .mdc-text-field {
        background: #FEEFEF;

        .mdc-floating-label {
            color: map-get(c.$colors, "failure", "200") !important;
        }
    }

    .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
    .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
    .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
        border-color: map-get(c.$colors, "failure", "200") !important;
    }

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
        border-width: 1px;
        border-color: map-get(c.$colors, "failure", "200") !important;
    }

    &:before {
        font-family: "Material Icons";
        content: "\e88e";
        position: absolute;
        top: 10px;
        right: 5px;
        font-size: map-get(t.$font, "size", "700");
        z-index: 2;
        color: map-get(c.$colors, "failure", "200");
    }
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
    --mdc-shape-small: 4px;
    border-color: map-get(c.$colors, "secondary", "400");
}

.mat-mdc-form-field .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    min-height: 45px !important;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.6) !important;
}




// Default color change
.mat-mdc-slide-toggle.mat-primary {
    --mdc-switch-selected-focus-state-layer-color: #580D7E;
    --mdc-switch-selected-handle-color: #580D7E;
    --mdc-switch-selected-hover-state-layer-color: #580D7E;
    --mdc-switch-selected-pressed-state-layer-color: #580D7E;
    --mdc-switch-selected-focus-handle-color: #CB63FF;
    --mdc-switch-selected-hover-handle-color: #CB63FF;
    --mdc-switch-selected-pressed-handle-color: #CB63FF;
    --mdc-switch-selected-focus-track-color: #7520A1;
    --mdc-switch-selected-hover-track-color: #7520A1;
    --mdc-switch-selected-pressed-track-color: #7520A1;
    --mdc-switch-selected-track-color: #7520A1;
}

.mat-mdc-radio-button.mat-primary {
    --mdc-radio-disabled-selected-icon-color: #000;
    --mdc-radio-disabled-unselected-icon-color: #000;
    --mdc-radio-unselected-focus-icon-color: #212121;
    --mdc-radio-unselected-hover-icon-color: #212121;
    --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-selected-focus-icon-color: #580D7E;
    --mdc-radio-selected-hover-icon-color: #580D7E;
    --mdc-radio-selected-icon-color: #580D7E;
    --mdc-radio-selected-pressed-icon-color: #580D7E;
    --mat-mdc-radio-ripple-color: #000;
    --mat-mdc-radio-checked-ripple-color: #580D7E;
}

.mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-selected-checkmark-color: #fff;
    --mdc-checkbox-selected-focus-icon-color: #580D7E;
    --mdc-checkbox-selected-hover-icon-color: #580D7E;
    --mdc-checkbox-selected-icon-color: #580D7E;
    --mdc-checkbox-selected-pressed-icon-color: #580D7E;
    --mdc-checkbox-unselected-focus-icon-color: #212121;
    --mdc-checkbox-unselected-hover-icon-color: #212121;
    --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
    --mdc-chip-elevated-container-color: #580D7E;
    --mdc-chip-elevated-disabled-container-color: #580D7E;
    --mdc-chip-label-text-color: white;
    --mdc-chip-disabled-label-text-color: white;
    --mdc-chip-with-icon-icon-color: white;
    --mdc-chip-with-icon-disabled-icon-color: white;
    --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
    --mdc-chip-with-trailing-icon-trailing-icon-color: white;
    --mdc-chip-with-icon-selected-icon-color: white;
}

.mdc-checkbox__background {
    --mdc-checkbox-selected-icon-color: #580D7E;
    --mdc-checkbox-selected-icon-color: #580D7E;
}
