@use '../abstracts/colors' as c;

.mat-mdc-tab {
    &.mdc-tab--active {
        .mdc-tab__text-label {
            font-family: 'Thrive bold';
            color: map-get(c.$colors, 'primary', '500') !important;
        }

        .mdc-tab-indicator .mdc-tab-indicator__content {
            border-top-width: 3px;
            border-radius: 100px 100px 0 0;
        }
    }

    .mdc-tab__text-label {
        letter-spacing: 0.5px;
    }
}

.mat-mdc-tab-group {
    border: 1px solid transparent !important;
    border-radius: 4px !important;
    overflow: hidden;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0px 2px rgba(96, 97, 112, 0.16);
}
