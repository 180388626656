@use '../abstracts/colors' as c;

// Responsive breakpoint manager
// @include respond-to('small')
$breakpoints: (
    'small': (max-width: 810px),
    'big': (min-width: 811px)
);

@mixin respond-to($breakpoint) {
    $raw-query: map-get($breakpoints, $breakpoint);

    @if $raw-query {
        $query: if(type-of($raw-query)=='string',
                unquote($raw-query),
                inspect($raw-query));

        @media #{$query} {
            @content;
        }
    }
}

// Get Flex properties
// @include get-flex('center', 'center', 'row')
@mixin get-flex($alignItems: normal, $justifyContent: flex-start, $flexDirection: row, $rowGap: 0, $columnGap: 0) {
    display: flex;
    align-items: $alignItems;
    justify-content: $justifyContent;
    flex-direction: $flexDirection;
    row-gap: $rowGap;
    column-gap: $columnGap;
}

// Get Grid properties
// @include get-grid('center', 'center', 'row')
@mixin get-grid($alignItems: normal, $justifyContent: flex-start, $rowGap: 0, $columnGap: 0, $gridTemplateColumns: 1fr) {
    display: grid;
    align-items: $alignItems;
    justify-content: $justifyContent;
    row-gap: $rowGap;
    column-gap: $columnGap;
    grid-template-columns: $gridTemplateColumns;
}

// Get Box Shadow
@mixin get-elevation() {
    box-shadow: 2px 2px 0px 0px map-get(c.$colors, 'secondary', '100');
}
