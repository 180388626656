@use '../abstracts' as a;
@use '../abstracts/mixins' as *;
@use '../abstracts/colors' as c;
@use 'sass:map';

body {
    font-family: 'Thrive Light' !important;
    font-size: map.get(a.$font, "size", "400") !important;
    line-height: map.get(a.$font, "line-height", "400");
    font-weight: map.get(a.$font, "weight", "regular") !important;
}

p {
    margin: 0;
}

.title {
    font-family: 'Thrive Bolder';
    font-size: map.get(a.$font, 'size', '900');
    font-weight: map.get(a.$font, 'weight', 'bolder');
    letter-spacing: auto;
    line-height: 130%;
}

.title_regular {
    font-family: 'Thrive Regular';
    font-size: map.get(a.$font, 'size', '900');
    font-weight: map.get(a.$font, 'weight', 'regular');
    letter-spacing: auto;
    line-height: 130%;
}

.title_light {
    font-family: 'Thrive Light';
    font-size: map.get(a.$font, 'size', '900');
    font-weight: map.get(a.$font, 'weight', 'light');
    letter-spacing: auto;
    line-height: 130%;
}

h1 {
    font-family: 'Thrive Regular';
    font-size: map.get(a.$font, 'size', '800');
    font-weight: map.get(a.$font, 'weight', 'bolder');
    letter-spacing: auto;
    line-height: 130%;
}

h2 {
    font-family: 'Thrive Regular';
    font-size: map.get(a.$font, "size", "700");
    font-weight: map.get(a.$font, "weight", "bolder");
    line-height: map.get(a.$font, "line-height", "700");
    margin: 0px;

    @include respond-to("small") {
        font-size: map.get(a.$font, "size", "500");
        line-height: map.get(a.$font, "line-height", "500");
    }

    &.h2_normal {
        font-family: 'Thrive Regular';
        font-weight: map.get(a.$font, 'weight', 'regular');
    }
}

h3 {
    font-family: 'Thrive Regular';
    font-size: map.get(a.$font, 'size', '600');
    font-weight: map.get(a.$font, 'weight', 'bolder');
    letter-spacing: auto;
    line-height: 140%;

    &.normal {
        font-family: 'Thrive Light';
        font-weight: map.get(a.$font, 'weight', 'regular');
    }

    @include respond-to('small') {
        font-size: map.get(a.$font, 'size', '400');
    }
}

//to be used for all headings on the card
h4 {
    &.sub-heading {
        font-family: 'Thrive Bold';
        font-size: map.get(a.$font, "size", "500");
        line-height: map.get(a.$font, "line-height", "500");
        font-weight: map.get(a.$font, "weight", "bold");
    }

    &.normal {
        font-family: 'Thrive Light';
        font-weight: map.get(a.$font, 'weight', 'regular');
    }
}

.subtitle {
    font-family: 'Thrive Bold';
    font-size: map.get(a.$font, 'size', '500');
    font-weight: map.get(a.$font, 'weight', 'bold');
    letter-spacing: auto;
    line-height: 150%;

    &.normal {
        font-family: 'Thrive Light';
        font-weight: map.get(a.$font, 'weight', 'regular');
    }

    @include respond-to('small') {
        font-size: map.get(a.$font, 'size', '300');
    }
}

.basetitle {
    font-family: 'Thrive Bold';
    font-size: map.get(a.$font, 'size', '400');
    font-weight: map.get(a.$font, 'weight', 'bold');
    letter-spacing: auto;
    line-height: 150%;

    &.normal {
        font-family: 'Thrive Light';
        font-weight: map.get(a.$font, 'weight', 'regular');
    }
}

.subbase {
    font-family: 'Thrive Regular';
    font-size: map.get(a.$font, 'size', '300');
    font-weight: map.get(a.$font, 'weight', 'regular');
    letter-spacing: auto;
    line-height: 160%;

    &.normal {
        font-family: 'Thrive Light';
        font-weight: map.get(a.$font, 'weight', 'regular');
    }
}

.pretitle {
    font-family: 'Thrive Bolder';
    font-size: map.get(a.$font, 'size', '200');
    font-weight: map.get(a.$font, 'weight', 'bolder');
    letter-spacing: auto;
    line-height: 160%;

    &.normal {
        font-family: 'Thrive Light';
        font-weight: map.get(a.$font, 'weight', 'regular');
    }
}

.pretitle-small {
    font-family: 'Thrive Bolder';
    font-size: map.get(a.$font, 'size', '100');
    font-weight: map.get(a.$font, 'weight', 'bolder');
    letter-spacing: auto;
    line-height: 160%;

    &.normal {
        font-family: 'Thrive Light';
        font-weight: map.get(a.$font, 'weight', 'regular');
    }
}

.link {
    font-family: 'Thrive Regular';
    font-weight: map.get(a.$font, 'weight', 'regular');
    text-decoration: underline;
    color: map-get(a.$colors, 'link');
    cursor: pointer;

    &:hover {
        color: map.get(a.$colors, 'primary', '500');
    }

    @include respond-to('small') {
        font-size: map.get(a.$font, 'size', '300');
    }
}

.text-bold {
    font-family: 'Thrive Bold';
    font-weight: map.get(a.$font, 'weight', 'bold');
}

//icons
.outline {
    font-family: 'Material Icons Outlined' !important;
}

.secondary-text {
    color: map.get(c.$colors, 'text', '300');
}

.secondary-text-2 {
    color: map.get(c.$colors, 'text', '200');
}
