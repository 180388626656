@use '../abstracts/mixins' as *;
@use '../abstracts/colors' as c;
@use '../helpers/variables' as v;
@use '../abstracts/type' as t;

.chip_success {
    background-color: map-get(c.$colors, "success", "100") !important;
    --mdc-chip-label-text-color: #018001 !important;
}

.chip_warn {
    background-color: map-get(c.$colors, "warning", "100") !important;
    --mdc-chip-label-text-color: #B95000 !important;
}

.chip_info {
    background-color: map-get(c.$colors, "info", "100") !important;
    --mdc-chip-label-text-color: #2E5AAC !important;
}

.chip_error {
    background-color: map-get(c.$colors, "failure", "100") !important;
    --mdc-chip-label-text-color: #DB1F2D !important;
}

.drag-placeholder {
    background: map-get(c.$colors, "secondary", "100");
    border: dotted 3px map-get(c.$colors, "secondary", "300");
    min-height: 60px;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.thrive-table {
    border: 1px solid map-get(c.$colors, "secondary", "100");
    border-radius: v.$border-radius;
    overflow: hidden;

    .table-container {
        height: calc(100vh - 330px);
        overflow-y: overlay;

        thead tr {
            background-color: map-get(c.$colors, "base", "grey-1") !important;
            font-family: 'Thrive Bold';
            font-weight: 700;
            vertical-align: top;
        }

        tr {
            border-bottom: 0.5px solid map-get(c.$colors, "secondary", "100");
            height: auto;

            &:last-child {
                border-bottom-width: 0px;
            }
        }

        td {
            padding: 15px;
            max-width: 300px;

            @include respond-to('small') {
                padding: 5px 10px;
            }
        }

        @include respond-to('small') {
            height: 100%;
        }
    }

    .mat-mdc-no-data-row td {
        div {
            height: calc(100vh - 430px);
            @include get-flex(center, center, column);

            img {
                width: 350px;
            }
        }
    }

    .mat-mdc-header-row,
    .mat-mdc-row {
        // border-bottom: 1px solid map-get(c.$colors, "secondary", "100");
        border-bottom: 0.5px solid map-get(c.$colors, "secondary", "100");
    }

    .mdc-data-table__cell {
        &.mat-mdc-table-sticky-border-elem-left {
            z-index: 2 !important;
        }
    }

    .mdc-data-table__header-cell,
    .mdc-data-table__cell {
        padding: 15px;
        vertical-align: middle;
        // border: 1px solid transparent;

        @include respond-to('small') {
            // padding: 15px 20px;
            padding: 10px;
        }

        .actions {
            @include get-flex(center, center);
            position: relative;
            z-index: 4;
        }

        .expand-btn {
            position: relative;
            z-index: 4;
        }

        .mat-mdc-slide-toggle {
            position: relative;
            z-index: 4;
        }
    }

    .mdc-data-table__header-cell {
        font-family: 'Thrive Bold';
        font-weight: 700;
        background-color: map-get(c.$colors, "base", "grey-1") !important;
        vertical-align: top;

        .sub-header {
            font-size: map-get(t.$font, "size", "200");
        }
    }

    .mat-mdc-paginator {
        background-color: map-get(c.$colors, "base", "grey-1") !important;
    }

    &.paginator-fixed {

        @include respond-to('small') {
            border: none;
            border-radius: 0px;
            padding-bottom: 60px;
        }

        .mat-mdc-paginator {
            @include respond-to('small') {
                position: fixed;
                left: 0px;
                bottom: 0px;
                width: 100%;
                z-index: 99;

                .mat-mdc-paginator-container {
                    justify-content: center;
                }
            }
        }
    }

    .mat-mdc-paginator-page-size {
        @include respond-to('small') {
            display: none;
        }
    }

    table:not(.mobile-view):not(.no-hover) {
        .mat-mdc-row {
            position: relative;

            &:hover {
                .mdc-data-table__cell {
                    cursor: pointer;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: -2px;
                    left: 0;
                    right: 0;
                    z-index: 3;
                    cursor: pointer;
                    box-shadow: 0px 8px 11px rgba(226, 226, 226, 0.45), 0px 8px 40px rgba(96, 97, 112, 0.25);
                }
            }
        }
    }
}
